import { apolloClient } from '@/apollo'

import GET_ORGANIZATIONS from '@/gql/query/getOrganizations.gql'
import CREATE_ORGANIZATION from '@/gql/mutation/organization/createOrganization.gql'

export default {
  namespaced: true,
  state: {
    organizations: [],
  },
  getters: {
    organizations(state) {
      return state.organizations
    },
  },
  mutations: {
    setOrganizations(state, data) {
      // eslint-disable-next-line no-undef
      state.organizations = structuredClone(data)
    },
  },
  actions: {
    setOrganizations({ commit }, data) {
      commit('setOrganizations', data)
    },
    async getOrganizations({ dispatch }) {
      const response = await apolloClient.query({
        query: GET_ORGANIZATIONS,
      })

      let organizations = response.data.getOrganizations
      if (!organizations) {
        organizations = []
      }

      dispatch('setOrganizations', organizations)
    },
    async createOrganization({ dispatch, getters }) {
      const response = await apolloClient.mutate({
        mutation: CREATE_ORGANIZATION,
      })

      dispatch('setOrganizations', [...getters.organizations, response.data.createOrganization])
    },
    clearOrganizationsData({ commit }) {
      commit('setOrganizations', [])
    },
  },
}
