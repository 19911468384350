export default {
  namespaced: true,
  state: {
    user: null,
    isUserFirstLogin: true,
  },
  getters: {
    user(state) {
      return state.user
    },
    isUserFirstLogin(state) {
      return state.isUserFirstLogin
    },
    isUserSporkMember(state) {
      return state.user && state.user.email.split('@')[1] === 'sporkinc.com'
    },
  },
  mutations: {
    setUser(state, data) {
      state.user = data
    },
    setIsUserFirstLogin(state, data) {
      state.isUserFirstLogin = data
    },
  },
  actions: {
    setUser({ commit }, data) {
      commit('setUser', data)
      localStorage.setItem('userData', JSON.stringify(data))
    },
    setIsUserFirstLogin({ commit }, data) {
      commit('setIsUserFirstLogin', data)
    },
  },
}
