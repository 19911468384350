import { apolloClient } from '@/apollo'

import GET_SCHEDULES from '@/gql/query/getSchedules.gql'

export default {
  namespaced: true,
  state: {
    isSchedulesFetched: false,
    schedules: [],
  },
  getters: {
    isSchedulesFetched(state) {
      return state.isSchedulesFetched
    },
    schedules(state) {
      return state.schedules
    },
  },
  mutations: {
    setIsSchedulesFetched(state, data) {
      state.isSchedulesFetched = data
    },
    setSchedules(state, data) {
      state.schedules = data
    },
  },
  actions: {
    setSchedules({ commit }, data) {
      commit('setSchedules', data)
    },
    async getSchedules({ rootGetters, dispatch, commit }) {
      const response = await apolloClient.query({
        query: GET_SCHEDULES,
        variables: {
          restaurantId: rootGetters['restaurant/restaurant'].id,
        },
      })
      const schedules = response.data.getSchedules

      dispatch('setSchedules', [...schedules.eventSchedules, ...schedules.menuSchedules])
      commit('setIsSchedulesFetched', true)
    },
    clearSchedulesData({ commit }) {
      commit('setIsSchedulesFetched', false)
      commit('setSchedules', [])
    },
  },
}
