import { apolloClient } from '@/apollo'

import GET_RESTAURANTS from '@/gql/query/getRestaurants.gql'
import CREATE_RESTAURANT from '@/gql/mutation/restaurant/createRestaurant.gql'

export default {
  namespaced: true,
  state: {
    restaurants: [],
    currentRestaurantId: null,
  },
  getters: {
    restaurants(state) {
      return state.restaurants
    },

    restaurant(state, getters) {
      if (!state.currentRestaurantId) {
        return null
      }

      const restaurant = getters.restaurants.find(i => i.id === state.currentRestaurantId)
      if (!restaurant) {
        return null
      }

      return restaurant
    },
    currencySymbol(state, getters, rootState) {
      let result = ''
      if (rootState.dictionaries.currencies.length && getters.restaurant) {
        const currency = rootState.dictionaries.currencies.find(i => i.code === getters.restaurant.primaryCurrency)
        if (currency) {
          result = currency.symbolNative
        }
      }
      return result
    },
    measurementUnitParams(state, getters, rootState) {
      if (getters.restaurant) {
        return Object.entries(rootState.dictionaries.measurementUnits)
          .find(j => j[0] === getters.restaurant.measurementUnits)[1]
      }
      return []
    },
  },
  mutations: {
    setRestaurants(state, data) {
      // eslint-disable-next-line no-undef
      state.restaurants = structuredClone(data)
    },
    setCurrentRestaurantId(state, data) {
      state.currentRestaurantId = data
    },
  },
  actions: {
    setRestaurants({ commit }, data) {
      commit('setRestaurants', data)
    },
    setCurrentRestaurantId({ commit }, data) {
      commit('setCurrentRestaurantId', data)
    },

    updateRestaurant({ commit, getters, state }, data) {
      const restaurants = getters.restaurants.map(i => (i.id === state.currentRestaurantId ? { ...i, ...data } : i))
      commit('setRestaurants', restaurants)
    },

    async getRestaurants({ dispatch }) {
      const response = await apolloClient.query({
        query: GET_RESTAURANTS,
      })

      dispatch('setRestaurants', response.data.getRestaurants)
    },
    async createRestaurant({ dispatch, rootGetters, getters }, data) {
      const response = await apolloClient.mutate({
        mutation: CREATE_RESTAURANT,
        variables: {
          organization: rootGetters['organizations/organizations'][0].id,
          name: data.name,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })

      dispatch('setRestaurants', [...getters.restaurants, response.data.createRestaurant])
    },

    clearRestaurantData({ commit }) {
      commit('setRestaurants', [])
      commit('setCurrentRestaurantId', null)
    },
  },
}
