import { apolloClient } from '@/apollo'

import GET_RESTAURANT_TYPES from '@/gql/query/dictionary/getRestaurantTypes.gql'
import GET_RESTAURANT_CUISINES from '@/gql/query/dictionary/getRestaurantCuisines.gql'
import GET_CURRENCIES from '@/gql/query/dictionary/getCurrencies.gql'
import GET_MEASUREMENT_UNITS from '@/gql/query/dictionary/getMeasurementUnits.gql'
import GET_LANGUAGES from '@/gql/query/dictionary/getLanguages.gql'
import GET_ALLERGENS from '@/gql/query/dictionary/getAllergens.gql'

export default {
  namespaced: true,
  state: {
    restaurantTypes: [],
    restaurantCuisines: [],
    currencies: [],
    measurementUnits: {
      Imperial: ['portion', 'items', 'lb', 'oz', 'fl oz'],
      Metric: ['portion', 'items', 'gm', 'ml'],
    },
    languages: [],
    allergens: [],

    // https://gist.github.com/keeguon/2310008
    // map from list of countries (should be in configuration) to text and value
    countries: [
      { value: 'AD', text: 'Andorra' },
      { value: 'AL', text: 'Albania' },
      { value: 'AT', text: 'Austria' },
      { value: 'AX', text: 'Åland Islands' },
      { value: 'BA', text: 'Bosnia and Herzegovina' },
      { value: 'BE', text: 'Belgium' },
      { value: 'BG', text: 'Bulgaria' },
      { value: 'BY', text: 'Belarus' },
      { value: 'CA', text: 'Canada' },
      { value: 'CY', text: 'Cyprus' },
      { value: 'CZ', text: 'Czech Republic' },
      { value: 'DE', text: 'Germany' },
      { value: 'DK', text: 'Denmark' },
      { value: 'EE', text: 'Estonia' },
      { value: 'ES', text: 'Spain' },
      { value: 'FI', text: 'Finland' },
      { value: 'FO', text: 'Faroe Islands' },
      { value: 'FR', text: 'France' },
      { value: 'GB', text: 'United Kingdom' },
      { value: 'GG', text: 'Guernsey' },
      { value: 'GR', text: 'Greece' },
      { value: 'HR', text: 'Croatia' },
      { value: 'HU', text: 'Hungary' },
      { value: 'IE', text: 'Ireland' },
      { value: 'IM', text: 'Isle of Man' },
      { value: 'IC', text: 'Iceland' },
      { value: 'IT', text: 'Italy' },
      { value: 'JE', text: 'Jersey' },
      { value: 'LI', text: 'Liechtenstein' },
      { value: 'LT', text: 'Lithuania' },
      { value: 'LU', text: 'Luxembourg' },
      { value: 'LV', text: 'Latvia' },
      { value: 'MC', text: 'Monaco' },
      { value: 'MD', text: 'Moldova, Republic of' },
      { value: 'MK', text: 'Macedonia, The Former Yugoslav Republic of' },
      { value: 'MT', text: 'Malta' },
      { value: 'NL', text: 'Netherlands' },
      { value: 'NO', text: 'Norway' },
      { value: 'PL', text: 'Poland' },
      { value: 'PT', text: 'Portugal' },
      { value: 'RO', text: 'Romania' },
      { value: 'SE', text: 'Sweden' },
      { value: 'CH', text: 'Switzerland' },
      { value: 'SI', text: 'Slovenia' },
      { value: 'SJ', text: 'Svalbard and Jan Mayen' },
      { value: 'SK', text: 'Slovakia' },
      { value: 'SM', text: 'San Marino' },
      { value: 'UA', text: 'Ukraine' },
      { value: 'VA', text: 'Holy See (Vatican City State)' },
      { value: 'US', text: 'United States' },
    ],

    timezones: [
      {
        id: 'UTC',
        name: 'UTC - Universal Coordinated Time (UTC)',
      },
      {
        id: 'Europe/Paris',
        name: 'ECT - European Central Time (UTC+1:00)',
      },
      {
        id: 'Europe/Bucharest',
        name: 'EET - Eastern European Time (UTC+2:00)',
      },
      {
        id: 'Africa/Cairo',
        name: 'ART - (Arabic) Egypt Standard Time (UTC+2:00)',
      },
      {
        id: 'Africa/Nairobi',
        name: 'EAT - Eastern African Time (UTC+3:00)',
      },
      {
        id: 'Asia/Tehran',
        name: 'MET - Middle East Time (UTC+3:30)',
      },
      {
        id: 'Asia/Yerevan',
        name: 'NET - Near East Time (UTC+4:00)',
      },
      {
        id: 'Asia/Karachi',
        name: 'PLT - Pakistan Lahore Time (UTC+5:00)',
      },
      {
        id: 'Asia/Kolkata',
        name: 'IST - India Standard Time (UTC+5:30)',
      },
      {
        id: 'Asia/Dhaka',
        name: 'BST - Bangladesh Standard Time (UTC+6:00)',
      },
      {
        id: 'Asia/Ho_Chi_Minh',
        name: 'VST - Vietnam Standard Time (UTC+7:00)',
      },
      {
        id: 'Asia/Shanghai',
        name: 'CTT - China Taiwan Time (UTC+8:00)',
      },
      {
        id: 'Asia/Tokyo',
        name: 'JST - Japan Standard Time (UTC+9:00)',
      },
      {
        id: 'Australia/Adelaide',
        name: 'ACT - Australia Central Time (UTC+9:30)',
      },
      {
        id: 'Australia/Sydney',
        name: 'AET - Australia Eastern Time (UTC+10:00)',
      },
      {
        id: 'Pacific/Guadalcanal',
        name: 'SST - Solomon Standard Time (UTC+11:00)',
      },
      {
        id: 'Pacific/Auckland',
        name: 'NST - New Zealand Standard Time (UTC+12:00)',
      },
      {
        id: 'Pacific/Midway',
        name: 'MIT - Midway Islands Time (UTC-11:00)',
      },
      {
        id: 'Pacific/Honolulu',
        name: 'HST - Hawaii Standard Time (UTC-10:00)',
      },
      {
        id: 'America/Anchorage',
        name: 'AST - Alaska Standard Time (UTC-9:00)',
      },
      {
        id: 'America/Los_Angeles',
        name: 'PST - Pacific Standard Time (UTC-8:00)',
      },
      {
        id: 'America/Phoenix',
        name: 'PNT - Phoenix Standard Time (UTC-7:00)',
      },
      {
        id: 'America/Denver',
        name: 'MST - Mountain Standard Time (UTC-7:00)',
      },
      {
        id: 'America/Chicago',
        name: 'CST - Central Standard Time (UTC-6:00)',
      },
      {
        id: 'America/New_York',
        name: 'EST - Eastern Standard Time (UTC-5:00)',
      },
      {
        id: 'America/Puerto_Rico',
        name: 'PRT - Puerto Rico and US Virgin Islands Time (UTC-4:00)',
      },
      {
        id: 'America/St_Johns',
        name: 'CNT - Canada Newfoundland Time (UTC-3:30)',
      },
      {
        id: 'America/Argentina/Buenos_Aires',
        name: 'AGT - Argentina Standard Time (UTC-3:00)',
      },
      {
        id: 'America/Sao_Paulo',
        name: 'BET - Brazil Eastern Time (UTC-3:00)',
      },
      {
        id: 'Africa/Maputo',
        name: 'CAT - Central African Time (UTC-1:00)',
      },
    ],
  },
  getters: {
    restaurantTypes(state) {
      return state.restaurantTypes
    },
    restaurantCuisines(state) {
      return state.restaurantCuisines
    },
    currencies(state) {
      return state.currencies
    },
    measurementUnits(state) {
      return state.measurementUnits
    },
    languages(state) {
      return state.languages
    },
    allergens(state) {
      return state.allergens
    },

    countries(state) {
      return state.countries
    },

    timezones(state) {
      return state.timezones
    },
  },
  mutations: {
    setRestaurantTypes(state, data) {
      state.restaurantTypes = data
    },
    setRestaurantCuisines(state, data) {
      state.restaurantCuisines = data
    },
    setCurrencies(state, data) {
      state.currencies = data
    },
    setMeasurementUnits(state, data) {
      state.measurementUnits = data
    },
    setLanguages(state, data) {
      state.languages = data
    },
    setAllergens(state, data) {
      state.allergens = data
    },
  },
  actions: {
    async getRestaurantTypes({ commit }) {
      const response = await apolloClient.query({
        query: GET_RESTAURANT_TYPES,
      })
      commit('setRestaurantTypes', response.data.getRestaurantTypes)
    },
    async getRestaurantCuisines({ commit }) {
      const response = await apolloClient.query({
        query: GET_RESTAURANT_CUISINES,
      })
      commit('setRestaurantCuisines', response.data.getRestaurantCuisines)
    },
    async getCurrencies({ commit }) {
      const response = await apolloClient.query({
        query: GET_CURRENCIES,
      })
      commit('setCurrencies', response.data.getCurrencies)
    },
    async getMeasurementUnits({ commit }) {
      const response = await apolloClient.query({
        query: GET_MEASUREMENT_UNITS,
      })
      commit('setMeasurementUnits', response.data.getMeasurementUnits)
    },
    async getLanguages({ commit }) {
      const response = await apolloClient.query({
        query: GET_LANGUAGES,
      })
      commit('setLanguages', response.data.getLanguages)
    },
    async getAllergens({ commit }) {
      const response = await apolloClient.query({
        query: GET_ALLERGENS,
      })
      commit('setAllergens', response.data.getAllergens)
    },
  },
}
