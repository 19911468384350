import { apolloClient } from '@/apollo'

import GET_ZONES from '@/gql/query/getZones.gql'
import CREATE_ZONE from '@/gql/mutation/zone/createZone.gql'
import UPDATE_ZONE from '@/gql/mutation/zone/updateZone.gql'

import GET_EVENTS from '@/gql/query/getEvents.gql'
import CREATE_EVENT from '@/gql/mutation/event/createEvent.gql'
import UPDATE_EVENT from '@/gql/mutation/event/updateEvent.gql'

export default {
  namespaced: true,
  state: {
    isZonesFetched: false,
    zones: [],

    isEventsFetched: false,
    events: [],
  },
  getters: {
    isZonesFetched(state) {
      return state.isZonesFetched
    },
    zones(state) {
      return state.zones
    },

    isEventsFetched(state) {
      return state.isEventsFetched
    },
    events(state) {
      return state.events
    },
  },
  mutations: {
    setIsZonesFetched(state, data) {
      state.isZonesFetched = data
    },
    setZones(state, data) {
      state.zones = data
    },

    setIsEventsFetched(state, data) {
      state.isEventsFetched = data
    },
    setEvents(state, data) {
      state.events = data
    },
  },
  actions: {
    setZones({ commit }, data) {
      commit('setZones', data)
    },
    async getZones({ rootGetters, dispatch, commit }) {
      const response = await apolloClient.query({
        query: GET_ZONES,
        variables: {
          restaurantId: rootGetters['restaurant/restaurant'].id,
        },
      })
      dispatch('setZones', response.data.getZones)
      commit('setIsZonesFetched', true)
    },

    setEvents({ commit }, data) {
      commit('setEvents', data)
    },
    async getEvents({ rootGetters, dispatch, commit }) {
      const response = await apolloClient.query({
        query: GET_EVENTS,
        variables: {
          restaurantId: rootGetters['restaurant/restaurant'].id,
        },
      })
      dispatch('setEvents', response.data.getEvents)
      commit('setIsEventsFetched', true)
    },

    async createZone({ rootGetters }, zoneName) {
      const response = await apolloClient.mutate({
        mutation: CREATE_ZONE,
        variables: {
          restaurant: rootGetters['restaurant/restaurant'].id,
          name: zoneName,
        },
      })

      // eslint-disable-next-line no-undef
      return structuredClone(response.data.createZone)
    },
    async createEvent({ rootGetters }, eventName) {
      const response = await apolloClient.mutate({
        mutation: CREATE_EVENT,
        variables: {
          restaurant: rootGetters['restaurant/restaurant'].id,
          name: eventName,
        },
      })

      // eslint-disable-next-line no-undef
      return structuredClone(response.data.createEvent)
    },

    updateZone(context, [id, param, value]) {
      return apolloClient.mutate({
        mutation: UPDATE_ZONE,
        variables: {
          updateZoneId: id,
          [param]: value,
        },
      })
    },
    updateEvent(context, [id, param, value]) {
      return apolloClient.mutate({
        mutation: UPDATE_EVENT,
        variables: {
          updateEventId: id,
          [param]: value,
        },
      })
    },

    clearFloorPlanData({ commit }) {
      commit('setIsZonesFetched', false)
      commit('setZones', [])

      commit('setIsEventsFetched', false)
      commit('setEvents', [])
    },
  },
}
