import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'

Vue.use(VueI18n)

export function getLocales() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return locales.keys()
    .map(i => i.match(/([A-Za-z0-9-_]+)\./i))
    .filter(i => i && i.length > 1)
    .map(i => i[1])
}

function getLocale() {
  let locale = store.getters['main/locale']

  if (!locale) {
    const fallbackLocale = 'en'

    const localesKeys = getLocales().filter(i => i !== fallbackLocale)
    localesKeys.forEach(localeKey => {
      if (navigator.language.toLowerCase().includes(localeKey)) {
        locale = localeKey
      }
    })

    if (!locale) {
      locale = fallbackLocale
    }
  }

  return locale
}

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale: getLocale(),
  messages: loadLocaleMessages(),
})
