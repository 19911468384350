import { apolloClient } from '@/apollo'

import GET_BADGES from '@/gql/query/badges-tags/getBadges.gql'
import GET_BADGES_TRANSLATIONS from '@/gql/query/badges-tags/getBadgesTranslations.gql'

import GET_TAGS from '@/gql/query/badges-tags/getTags.gql'
import GET_TAGS_TRANSLATIONS from '@/gql/query/badges-tags/getTagsTranslations.gql'

export default {
  namespaced: true,
  state: {
    isBadgesFetched: false,
    badges: [],
    badgesTranslations: [],

    isTagsFetched: false,
    tags: [],
    tagsTranslations: [],
  },
  getters: {
    isBadgesFetched(state) {
      return state.isBadgesFetched
    },
    badges(state) {
      return state.badges
    },
    badgesTranslations(state) {
      return state.badgesTranslations
    },

    isTagsFetched(state) {
      return state.isTagsFetched
    },
    tags(state) {
      return state.tags
    },
    tagsTranslations(state) {
      return state.tagsTranslations
    },
  },
  mutations: {
    setIsBadgesFetched(state, data) {
      state.isBadgesFetched = data
    },
    setBadges(state, data) {
      state.badges = data
    },
    setBadgesTranslations(state, data) {
      state.badgesTranslations = data
    },

    setIsTagsFetched(state, data) {
      state.isTagsFetched = data
    },
    setTags(state, data) {
      state.tags = data
    },
    setTagsTranslations(state, data) {
      state.tagsTranslations = data
    },
  },
  actions: {
    setBadges({ commit }, data) {
      commit('setBadges', data)
    },
    async getBadges({ rootGetters, dispatch }) {
      const response = await apolloClient.query({
        query: GET_BADGES,
        variables: {
          restaurantId: rootGetters['restaurant/restaurant'].id,
        },
      })
      dispatch('setBadges', response.data.getBadges)
    },
    setBadgesTranslations({ commit }, data) {
      commit('setBadgesTranslations', data)
    },
    async getBadgesTranslations({ rootGetters, dispatch }) {
      const response = await apolloClient.query({
        query: GET_BADGES_TRANSLATIONS,
        variables: {
          restaurantId: rootGetters['restaurant/restaurant'].id,
        },
      })
      dispatch('setBadgesTranslations', response.data.getBadgesTranslations)
    },
    async getBadgesAllData({ dispatch, commit }) {
      await Promise.all([
        dispatch('getBadges'),
        dispatch('getBadgesTranslations'),
      ])

      commit('setIsBadgesFetched', true)
    },

    setTags({ commit }, data) {
      commit('setTags', data)
    },
    async getTags({ rootGetters, dispatch }) {
      const response = await apolloClient.query({
        query: GET_TAGS,
        variables: {
          restaurantId: rootGetters['restaurant/restaurant'].id,
        },
      })
      dispatch('setTags', response.data.getTags)
    },
    setTagsTranslations({ commit }, data) {
      commit('setTagsTranslations', data)
    },
    async getTagsTranslations({ rootGetters, dispatch }) {
      const response = await apolloClient.query({
        query: GET_TAGS_TRANSLATIONS,
        variables: {
          restaurantId: rootGetters['restaurant/restaurant'].id,
        },
      })
      dispatch('setTagsTranslations', response.data.getTagsTranslations)
    },
    async getTagsAllData({ dispatch, commit }) {
      await Promise.all([
        dispatch('getTags'),
        dispatch('getTagsTranslations'),
      ])

      commit('setIsTagsFetched', true)
    },

    async getBadgesTagsAllData({ dispatch }) {
      await Promise.all([
        dispatch('getBadgesAllData'),
        dispatch('getTagsAllData'),
      ])
    },
  },
}
