import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import store from '@/store'
import i18n, { getLocales } from '@/libs/i18n'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: 'Dashboard' */ '@/views/Dashboard.vue'),
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import(/* webpackChunkName: 'Orders' */ '@/views/orders/Orders.vue'),
      redirect: { name: 'orders-local' },
      children: [
        {
          path: 'local',
          name: 'orders-local',
          component: () => import(/* webpackChunkName: 'OrdersLocal' */ '@/views/orders/OrdersLocal.vue'),
        },
        {
          path: 'history',
          name: 'orders-history',
          component: () => import(/* webpackChunkName: 'OrdersHistory' */ '@/views/orders/OrdersHistory.vue'),
        },
      ],
    },
    {
      path: '/menu-management',
      name: 'menu-management',
      component: () => import(/* webpackChunkName: 'MenuManagement' */ '@/views/MenuManagement.vue'),
      children: [
        {
          path: 'menus/:id',
          name: 'menu',
          component: () => import(/* webpackChunkName: 'Menu' */ '@/views/Menu.vue'),
        },
        {
          path: 'menu-items/:id',
          name: 'menu-item',
          component: () => import(/* webpackChunkName: 'MenuItem' */ '@/views/MenuItem.vue'),
        },
        {
          path: 'combination-menu-items/:id',
          name: 'combination-menu-item',
          component: () => import(/* webpackChunkName: 'CombinationMenuItem' */ '@/views/CombinationMenuItem.vue'),
        },
      ],
    },
    {
      path: '/events',
      name: 'events',
      component: () => import(/* webpackChunkName: 'Events' */ '@/views/Events.vue'),
      children: [
        {
          path: ':id',
          name: 'event',
          component: () => import(/* webpackChunkName: 'Event' */ '@/views/Event.vue'),
        },
      ],
    },

    {
      path: '/restaurant/settings',
      name: 'settings',
      component: () => import(/* webpackChunkName: 'Settings' */ '@/views/restaurant/Settings.vue'),
    },
    {
      path: '/restaurant/integrations',
      name: 'integrations',
      component: () => import(/* webpackChunkName: 'Integrations' */ '@/views/restaurant/Integrations.vue'),
    },
    // {
    //   path: '/restaurant/integrations/:integration',
    //   name: 'integrations-processing',
    //   component: () => import(/* webpackChunkName: 'IntegrationsProcessing' */ '@/views/restaurant/IntegrationsProcessing.vue'),
    // },
    {
      path: '/restaurant/add-ons',
      name: 'add-ons_settings',
      component: () => import(/* webpackChunkName: 'AddOnsSettings' */ '@/views/restaurant/AddOnsSettings.vue'),
    },
    {
      path: '/restaurant/zones',
      name: 'zones',
      component: () => import(/* webpackChunkName: 'Zones' */ '@/views/restaurant/Zones.vue'),
      children: [
        {
          path: ':id',
          name: 'zone',
          component: () => import(/* webpackChunkName: 'Zone' */ '@/views/restaurant/Zone.vue'),
        },
      ],
    },
    {
      path: '/restaurant/themes',
      name: 'themes',
      component: () => import(/* webpackChunkName: 'Themes' */ '@/views/restaurant/Themes.vue'),
    },
    {
      path: '/restaurant/paper-menu-designer',
      name: 'paper-menu-designer',
      component: () => import(/* webpackChunkName: 'PaperMenuDesigner' */ '@/views/restaurant/PaperMenuDesigner.vue'),
    },
    {
      path: '/restaurant/qr',
      name: 'qr',
      component: () => import(/* webpackChunkName: 'Qr' */ '@/views/restaurant/Qr.vue'),
    },

    // {
    //   path: '/account/restaurants',
    //   name: 'restaurants',
    //   component: () => import(/* webpackChunkName: 'Restaurants' */ '@/views/Restaurants.vue'),
    // },
    {
      path: '/account/billing',
      name: 'billing',
      component: () => import(/* webpackChunkName: 'Billing' */ '@/views/Billing.vue'),
    },
    {
      path: '/account/add-ons',
      name: 'add-ons',
      component: () => import(/* webpackChunkName: 'AddOns' */ '@/views/AddOns.vue'),
    },
    {
      path: '/account/users',
      name: 'users',
      component: () => import(/* webpackChunkName: 'Users' */ '@/views/Users.vue'),
    },

    {
      path: '/account-settings',
      name: 'account-settings',
      component: () => import(/* webpackChunkName: 'AccountSetting' */ '@/views/account-setting/AccountSetting.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: 'Login' */ '@/views/Login.vue'),
      meta: {
        redirectIfLoggedIn: true,
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import(/* webpackChunkName: 'Register' */ '@/views/Register.vue'),
      meta: {
        redirectIfLoggedIn: true,
        layout: 'full',
      },
    },
    {
      path: '/register-success',
      name: 'register-success',
      component: () => import(/* webpackChunkName: 'Register' */ '@/views/RegisterSuccess.vue'),
      meta: {
        redirectIfLoggedIn: true,
        layout: 'full',
      },
    },
    {
      path: '/invitation',
      name: 'invitation',
      component: () => import(/* webpackChunkName: 'Invitation' */ '@/views/Invitation.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import(/* webpackChunkName: 'ForgotPassword' */ '@/views/ForgotPassword.vue'),
      meta: {
        redirectIfLoggedIn: true,
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: 'ResetPassword' */ '@/views/ResetPassword.vue'),
      meta: {
        redirectIfLoggedIn: true,
        layout: 'full',
      },
    },
    // {
    //   path: '/faq',
    //   name: 'faq',
    //   component: () => import(/* webpackChunkName: 'FAQ' */ '@/views/FAQ.vue'),
    // },
    // {
    //   path: '/trash-bin',
    //   name: 'trash-bin',
    //   component: () => import(/* webpackChunkName: 'TrashBin' */ '../views/TrashBin.vue'),
    // },
    {
      path: '/email-confirm',
      name: 'email-confirm',
      component: () => import(/* webpackChunkName: 'EmailConfirm' */ '@/views/EmailConfirm.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error',
      name: 'error',
      component: () => import(/* webpackChunkName: 'Error' */ '@/views/Error.vue'),
      meta: {
        layout: 'full',
      },
    },
    // redirect to App, on demo restaurant, La Terrazza
    // source link, printed on business card: https://hub.sporkinc.com/api/v1/hardcoded/menu-preview
    {
      path: '/api/v1/hardcoded/menu-preview',
      redirect: () => {
        window.open(`${process.env.VUE_APP_ORIGIN_CLIENT}/62fwktyw1a`, '_self')
      },
    },
    {
      path: '*',
      redirect: 'error',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const { lang, country } = to.query
  if (lang && getLocales().find(i => i === lang)) {
    store.dispatch('main/setLocale', lang)
    i18n.locale = lang
  }
  if (country) {
    const countryData = store.getters['dictionaries/countries'].find(i => i.value === country.toUpperCase())
    if (countryData) {
      store.dispatch('main/setCountryRegistration', countryData.value)
    }
  }

  if (isUserLoggedIn()) {
    if (to.meta.redirectIfLoggedIn) {
      next({ name: from.name || 'dashboard' })
    } else {
      next()
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (to.meta.redirectIfLoggedIn) {
      next()
    } else if (to.name === 'invitation') {
      next({ name: 'login', params: { invitationCode: to.query.invitationCode } })
    } else {
      next({ name: 'login' })
    }
  }
})

export default router
