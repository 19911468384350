import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { WebSocketLink } from 'apollo-link-ws'
import { split, ApolloLink } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { ApolloProvider } from 'vue-apollo'
import { setContext } from 'apollo-link-context'
import createUploadLink from 'apollo-upload-client/createUploadLink'

import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'

function getAuthorizationToken() {
  const token = localStorage.getItem(jwtDefaultConfig.storageTokenKeyName)
  return token ? `${jwtDefaultConfig.tokenType} ${token}` : ''
}

const wsLink = new WebSocketLink({
  uri: `wss://${process.env.VUE_APP_API_HOST}/graphql`,
  options: {
    reconnect: true,
    connectionParams: () => ({
      headers: {
        authorization: getAuthorizationToken(),
      },
    }),
  },
  onError: error => {
    // eslint-disable-next-line no-console
    console.error('WebSocket link error:', error)
  },
})

// const loggingLink = new ApolloLink(
//   (operation, forward) => forward(operation)
//     .map(response => {
//       console.log('Apollo response: ', response)
//       return response
//     }),
// )

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: getAuthorizationToken(),
    'apollo-require-preflight': true,
  },
}))

// eslint-disable-next-line new-cap
const httpLink = new createUploadLink({
  uri: `https://${process.env.VUE_APP_API_HOST}/graphql`,
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  ApolloLink.from([
    // loggingLink,
    authLink,
    httpLink,
  ]),
)

const apolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
})

const apolloProvider = new ApolloProvider({
  defaultClient: apolloClient,
})

export { apolloClient, ApolloProvider, apolloProvider }
