import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import floorPlan from './floor-plan'
import restaurant from './restaurant'
import dictionaries from './dictionaries'
import menuManagement from './menu-management'
import billing from './billing'
import schedules from './schedules'
import organizations from './organizations'
import main from './main'
import badgesTags from './badges-tags'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
    floorPlan,
    restaurant,
    dictionaries,
    menuManagement,
    billing,
    schedules,
    organizations,
    main,
    badgesTags,
  },
  plugins: [
    createPersistedState({
      key: 'spork_admin',
      paths: [
        'main',
        'user.isUserFirstLogin',
      ],
    }),
  ],
  strict: process.env.DEV,
})
